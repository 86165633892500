/* eslint-disable react/prop-types */
import empty from "empty";
import { includes } from "lodash/fp";
import React from "react";

import LandscapeFilters from "../../filters";
import Landscape from "../landscape";
import LandscapeLegend from "../landscape/legend";
import { isLandscape } from "../landscape/utils";

export const renderLandscape =
  (relationType) =>
  ({
    entitylandscape,
    entitylandscapeItem,
    landscape: applicationLandscape,
    landscapeItem: applicationLandscapeItem,
    landscapeFilters,
    uiActions,
    uiClasses,
  }) => {
    const landscape =
      relationType === "entiteit_entiteit"
        ? entitylandscape
        : applicationLandscape;
    const landscapeItem =
      relationType === "entiteit_entiteit"
        ? entitylandscapeItem
        : applicationLandscapeItem;
    return isLandscape(landscape) ? (
      <Landscape
        key={`Landscape_${relationType}`}
        landscape={landscape}
        landscapeItem={landscapeItem}
        relationType={relationType}
        filters={landscapeFilters}
        uiActions={uiActions}
        isFullscreen={includes("fullscreen")(uiClasses)}
      />
    ) : null;
  };

export const renderLandscapeLegend = ({
  selectionLists,
  tabInfo: { active } = empty.object,
  pagetype,
}) => {
  const automatiseringsgraadList = selectionLists?.find(
    (l) => l.alias === "automatiseringsgraad"
  );
  return (active === "Landschap" ||
    active === "Applicaties" ||
    active === "Entiteiten") &&
    pagetype ? (
    <LandscapeLegend
      key="landscapeLegend"
      automatiseringsgraadList={automatiseringsgraadList}
      fromItem={active !== "Applicaties"}
      pagetype={active === "Applicaties" ? "applicatie" : pagetype}
    />
  ) : null;
};

export const renderLandscapeFilters = ({
  landscapeFilters,
  tabInfo: { active } = empty.object,
}) =>
  active === "Landschap" || active === "Applicaties" ? (
    <LandscapeFilters key="landscapeFilters" filters={landscapeFilters} />
  ) : null;
