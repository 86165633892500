import {
  AsyncResponse,
  RequestCallback,
  ScopeRelation,
  Some,
  SomeOrVoid,
  asyncInitial,
  createActor,
  createList,
  createSearch,
  createSingle,
  createSparse,
  httpIsSuccess,
  immediateReducer,
} from "async-lifecycle-saga";
import { isEqual } from "lodash/fp";
import { Reducer, combineReducers } from "redux";
import { ForkEffectDescriptor, SimpleEffect } from "redux-saga/effects";

import { postFetch, variantFetch } from "./api/fetch";
import {
  CopyItemModel,
  IndexView,
  IndexViewColumnGroup,
  IndexViewData,
  IndexViewVisibility,
  LinkModel,
  SisenseTokenOutput,
  SiteLink,
  emptyArray,
  emptyObject,
} from "./business/models";

interface Store {
  sagas: {
    indexItem: number;
    indexFilter: boolean;
  };
}

const minisaarSpecific =
  (reducer: Reducer<any, any>): Reducer<any, any> =>
  (state = asyncInitial, action) =>
    action.type === "MINISAAR_INIT" && Boolean(action.extra?.changed)
      ? asyncInitial
      : reducer(state, action);

type Sagas = () => IterableIterator<
  SimpleEffect<"FORK", ForkEffectDescriptor<{}>>
>;

const processTree = createSingle({
  path: ["sagas", "processTree"],
  require: {
    api: () => variantFetch("process/0/multitree", {}),
  },
});

const orderRelations = createActor<
  { itemId: number; direction?: number; ids: number[] },
  void
>({
  path: ["sagas", "orderRelations"],
  api: ({ itemId, direction, ids }) =>
    variantFetch(`cms/relations/order/${direction ?? "van"}/${itemId}`, {
      ids,
    }),
});

const orderRootItems = createActor<{ ids: number[] }, void>({
  path: ["sagas", "orderRootItems"],
  api: ({ ids }) => variantFetch("cms/rootitems/order", { ids }),
});

const clustersForRelations = createSearch<
  { id: number; clusterId: number },
  Some
>({
  path: ["sagas", "clustersForRelations"],
  api: ({ id, clusterId }) => variantFetch(`page/${id}/clusters/${clusterId}`),
  compare: (a, b) =>
    isEqual(a, b) ? ScopeRelation.equal : ScopeRelation.unrelated,
});

const copyItem = createActor<CopyItemModel, void>({
  path: ["sagas", "copyItem"],
  api: ({ sourceItemId, label, withHierarchyRelations, structureId }) =>
    variantFetch(`cms/page/copy/${sourceItemId}`, {
      label,
      withHierarchyRelations,
      structureId,
    }),
});

const sisenseLogin = createSingle<SisenseTokenOutput, Some>({
  path: ["sagas", "sisenseLogin"],
  require: {
    api: () => postFetch("sisense/token"),
  },
});

const tabForCluster = createSearch<
  { itemId: number; clusterId: number },
  { tab: number }
>({
  path: ["sagas", "tabForCluster"],
  api: ({ itemId, clusterId }) =>
    variantFetch(`presentation/tab/${itemId}?clusterId=${clusterId}`),
  compare: (a, b) =>
    isEqual(a, b) ? ScopeRelation.equal : ScopeRelation.unrelated,
});

const biebPagetypeMapping = createSearch<{ biebItemId: number }, Some>({
  path: ["sagas", "biebPagetypeMapping"],
  api: ({ biebItemId }) => variantFetch(`cms/relations/bieb/${biebItemId}`),
  compare: (a, b) =>
    isEqual(a, b) ? ScopeRelation.equal : ScopeRelation.unrelated,
});

const parseIndexView = (r: IdxVewTab): IndexView => {
  const cur = r.cur ? JSON.parse(r.cur) : emptyObject();
  return {
    id: r.idxVewIdt as number,
    own: r.own ?? false,
    name: r.nam as string,
    visibility: r.pub as IndexViewVisibility,
    filter: cur.filter ?? "",
    columnFilters: cur.columnFilters ?? emptyObject(),
    queryString: cur.queryString || "?",
    order: cur.order ?? { descending: false },
    columns: cur.columns ?? emptyArray(),
  };
};

const serializeIndexView = (
  { name, visibility, ...cur }: IndexViewData,
  id: number | undefined = undefined
): IdxVewTab => ({
  idxVewIdt: id ?? 0,
  nam: name,
  pub: visibility,
  cur: JSON.stringify(cur),
});

interface IdxVewTab {
  idxVewIdt: number;
  own?: boolean;
  nam: string;
  pub: number;
  cur: string;
}

export const indexViews = createList<
  IndexView,
  Store,
  number,
  IndexView,
  IndexViewData,
  IndexView
>({
  path: ["sagas", "indexViews"],
  context: (state) => state.sagas.indexItem,
  require: {
    api: async (_: void, itmIdt: number) => {
      if (!itmIdt) {
        return { status: 200, body: [] };
      }

      const raw = (await variantFetch(`indexview/${itmIdt}`)) as AsyncResponse<
        IdxVewTab[]
      >;
      if (!httpIsSuccess(raw)) {
        return raw as AsyncResponse<IndexView[]>;
      }

      const body = raw.body.map(parseIndexView);
      return { ...raw, body } as AsyncResponse<IndexView[]>;
    },
  },
  create: {
    api: (v: IndexViewData, itmIdt: number) =>
      variantFetch(`indexview/${itmIdt}`, serializeIndexView(v)) as Promise<
        AsyncResponse<IndexView>
      >,
  },
  update: {
    api: (v: IndexView, itmIdt: number) =>
      variantFetch(
        `indexview/${itmIdt}/${v.id}`,
        serializeIndexView(v, v.id),
        undefined,
        { method: "PUT" }
      ) as Promise<AsyncResponse<IndexView>>,
  },
  delete: {
    api: (v: IndexView, itmIdt: number) =>
      variantFetch(`indexview/${itmIdt}/${v.id}`, undefined, undefined, {
        method: "DELETE",
      }) as Promise<AsyncResponse<IndexView>>,
  },
});

export const indexFilter = {
  reducer: immediateReducer("SAGAS_INDEXFILTER", true),
  select: (state: Store): boolean => state.sagas.indexFilter,
  set: (visible: boolean) => {
    return {
      type: "SAGAS_INDEXFILTER_SET",
      payload: visible,
    };
  },
};

export const indexItem = {
  reducer: immediateReducer("SAGAS_INDEXITEM", 0),
  select: (state: Store): number => state.sagas.indexItem,
  set: (itemId: number) => {
    return {
      type: "SAGAS_INDEXITEM_SET",
      payload: itemId,
    };
  },
};

export const columnsForIndex = createSearch<
  { id: number; pagetypes: string[] },
  IndexViewColumnGroup[]
>({
  path: ["sagas", "columnsForIndex"],
  api: ({ id, pagetypes }) =>
    variantFetch(`indexview/${id}/columns`, { pagetypes }),
  compare: (a, b) =>
    isEqual(a, b) ? ScopeRelation.equal : ScopeRelation.unrelated,
});

export const findBiebItems = createSearch<number, SiteLink[]>({
  path: ["sagas", "findBiebItems"],
  api: (saarItemId: number) => variantFetch(`cms/bieb/link/${saarItemId}`),
  compare: () => ScopeRelation.unrelated,
});

export const linkItem = createActor<LinkModel, void>({
  path: ["sagas", "linkItem"],
  api: (link) => variantFetch(`cms/bieb/link`, link),
});

export const unlinkItem = createActor<LinkModel, void>({
  path: ["sagas", "unlinkItem"],
  api: (link) => variantFetch(`cms/bieb/unlink`, link),
});

export const bulkcopy = createActor<number[], void>({
  path: ["sagas", "bulkcopy"],
  api: (itemIds) => variantFetch(`cms/page/bulkcopy`, { itemIds }),
});

interface RecentIndexView {
  id: number;
  idxVewIdt?: number;
}
export const recentIndexViews = createSparse<
  RecentIndexView,
  number,
  Some,
  undefined,
  RecentIndexView
>({
  path: ["sagas", "recentIndexViews"],
  require: {
    api: (arg) => variantFetch(`indexview/${arg.id}/recent`),
  },
  update: {
    api: (value) => variantFetch(`indexview/${value.id}/recent`, value),
  },
});

export const sagas = {
  processTree: processTree.events,
  orderRelations: orderRelations.events,
  orderRootItems: orderRootItems.events,
  clustersForRelations: clustersForRelations.events,
  copyItem: copyItem.events,
  sisenseLogin: sisenseLogin.events,
  tabForCluster: tabForCluster.events,
  biebPagetypeMapping: biebPagetypeMapping.events,
  indexViews: indexViews.events,
  columnsForIndex: columnsForIndex.events,
  findBiebItems: findBiebItems.events,
  linkItem: linkItem.events,
  unlinkItem: unlinkItem.events,
  bulkcopy: bulkcopy.events,
  recentIndexViews: recentIndexViews.events,
};

const initSagas = (): { reducer: Reducer<any, any>; sagas: Sagas } => {
  return {
    reducer: combineReducers({
      processTree: minisaarSpecific(processTree.reducer),
      orderRelations: orderRelations.reducer,
      orderRootItems: orderRootItems.reducer,
      clustersForRelations: clustersForRelations.reducer,
      copyItem: copyItem.reducer,
      sisenseLogin: sisenseLogin.reducer,
      tabForCluster: tabForCluster.reducer,
      biebPagetypeMapping: biebPagetypeMapping.reducer,
      indexItem: indexItem.reducer,
      indexFilter: indexFilter.reducer,
      indexViews: indexViews.reducer,
      columnsForIndex: columnsForIndex.reducer,
      findBiebItems: findBiebItems.reducer,
      linkItem: linkItem.reducer,
      unlinkItem: unlinkItem.reducer,
      bulkcopy: bulkcopy.reducer,
      recentIndexViews: recentIndexViews.reducer,
    }),
    sagas: function* () {
      yield* processTree.sagas;
      yield* orderRelations.sagas;
      yield* orderRootItems.sagas;
      yield* clustersForRelations.sagas;
      yield* copyItem.sagas;
      yield* sisenseLogin.sagas;
      yield* tabForCluster.sagas;
      yield* biebPagetypeMapping.sagas;
      yield* indexViews.sagas;
      yield* columnsForIndex.sagas;
      yield* findBiebItems.sagas;
      yield* linkItem.sagas;
      yield* unlinkItem.sagas;
      yield* bulkcopy.sagas;
      yield* recentIndexViews.sagas;
    },
  };
};

export const combineCallbacks = <TValue extends SomeOrVoid = SomeOrVoid>(
  ...callbacks: RequestCallback<TValue>[]
): RequestCallback<TValue> => ({
  onSuccess: (result) => callbacks.forEach((cb) => cb.onSuccess?.(result)),
  onCancel: () => callbacks.forEach((cb) => cb.onCancel?.()),
  onFail: (problemDetails) =>
    callbacks.forEach((cb) => cb.onFail?.(problemDetails)),
});

export default initSagas;
