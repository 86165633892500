/* eslint  react/prop-types: off */
import empty from "empty";
import { flatten, get, map, some, sortBy } from "lodash/fp";
import { Card } from "material-ui/Card";
import FlatButton from "material-ui/FlatButton";
import ContentLink from "material-ui/svg-icons/content/link";
import React, { PureComponent } from "react";
import { ReactSVGPanZoom } from "react-svg-pan-zoom";

import Dialog from "../../../containers/dialog";
import Subheader from "../../material/Subheader";
import { ItemLinkList } from "../../relations";
import LandscapePopup from "./popup";
import { Line, Rectangle } from "./shapes";
import LandscapeToolbar from "./toolbar";
import { usingHighlighted } from "./utils";

let scrollEnabled = true;

function preventDefault(e) {
  if (e.preventDefault) e.preventDefault();
  e.returnValue = false;
}

function disableScroll() {
  if (!scrollEnabled) {
    return;
  }

  scrollEnabled = false;
  document.addEventListener("wheel", preventDefault, { passive: false });
  if (window.addEventListener) {
    window.addEventListener("DOMMouseScroll", preventDefault, false);
  }
}

function enableScroll() {
  if (scrollEnabled) {
    return;
  }

  scrollEnabled = true;
  document.removeEventListener("wheel", preventDefault, { passive: false });
  if (window.removeEventListener) {
    window.removeEventListener("DOMMouseScroll", preventDefault, false);
  }
}

class Landscape extends PureComponent {
  constructor(props, context) {
    super(props, context);

    this.state = {
      tool: "none",
      fullscreen: false,
    };

    this.Viewer = null;

    this.toggleNone = this.toggleNone.bind(this);
    this.togglePan = this.togglePan.bind(this);
    this.toggleZoomIn = this.toggleZoomIn.bind(this);
    this.toggleZoomOut = this.toggleZoomOut.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
    this.fitToViewer = this.fitToViewer.bind(this);

    this.renderLine = this.renderLine.bind(this);
    this.renderRectangle = this.renderRectangle.bind(this);

    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    const {
      isFullscreen = false,
      landscape: {
        size: { height = 0, width = 0 } = empty.object,
      } = empty.object,
      containerWidth,
    } = this.props;
    this.Viewer.reset();

    if (containerWidth > width) {
      this.Viewer.setPointOnViewerCenter(width / 2, height / 2, 1.1);
    } else {
      this.Viewer.fitToViewer();
    }

    this.setState({ fullscreen: isFullscreen });
  }

  componentWillUnmount() {
    enableScroll();
  }

  componentDidUpdate({
    landscape: {
      size: { height = 0, width = 0 } = empty.object,
    } = empty.object,
    containerWidth,
  }) {
    if (this.props.containerWidth !== containerWidth) {
      if (containerWidth > width) {
        this.Viewer.setPointOnViewerCenter(width / 2, height / 2, 1.1);
      } else {
        this.Viewer.fitToViewer();
      }
    }
  }

  toggleFullscreen() {
    const { fullscreen } = this.state;

    if (fullscreen) {
      this.props.uiActions.removeClass("fullscreen");
      this.setState({ fullscreen: false });
    } else {
      this.props.uiActions.addClass("fullscreen");
      this.setState({ fullscreen: true });
    }
  }

  toggleNone() {
    this.setState({
      tool: "none",
    });
  }

  togglePan() {
    const pan = this.state.tool === "pan";
    this.setState({
      tool: pan ? "none" : "pan",
    });
  }

  toggleZoomIn() {
    const zoomIn = this.state.tool === "zoom-in";
    this.setState({
      tool: zoomIn ? "none" : "zoom-in",
    });
  }

  toggleZoomOut() {
    const zoomOut = this.state.tool === "zoom-out";
    this.setState({
      tool: zoomOut ? "none" : "zoom-out",
    });
  }

  fitToViewer() {
    this.Viewer.fitToViewer();
  }

  handleClose() {
    this.props.handlePopupDialog();
  }

  // eslint-disable-next-line class-methods-use-this
  renderMarkers(suffix) {
    return [
      <marker
        id={`arrow${suffix}`}
        className={suffix}
        key={`arrow${suffix}`}
        viewBox="0 0 60 60"
        refX="60"
        refY="30"
        markerUnits="strokeWidth"
        markerWidth="6"
        markerHeight="7"
        orient="auto"
      >
        <path d="M 0 0 L 60 30 L 0 60 z" />
      </marker>,
      <marker
        id={`arrow_rev${suffix}`}
        className={suffix}
        key={`arrow_rev${suffix}`}
        viewBox="0 0 60 60"
        refX="30"
        refY="30"
        markerUnits="strokeWidth"
        markerWidth="6"
        markerHeight="7"
        orient="auto"
      >
        <path d="M 60 0 L 0 30 L 60 60 z" />
      </marker>,
    ];
  }

  renderLine(l) {
    const {
      landscape: { lines = empty.array } = empty.object,
      handlePopupDialog,
      showLinkIcon,
      hideLinkIcon,
      filters: {
        filter = empty.array,
        selectedItemIds = empty.object,
      } = empty.object,
    } = this.props;

    const line = lines[l];
    const highlightedLine =
      filter.length === 0 ||
      some(Boolean)(map(usingHighlighted(selectedItemIds))(line.links));
    return (
      <Line
        key={l}
        l={line}
        handlePopupDialog={handlePopupDialog}
        showLinkIcon={showLinkIcon}
        hideLinkIcon={hideLinkIcon}
        highlighted={highlightedLine}
      />
    );
  }

  renderRectangle(r) {
    const {
      theme: {
        palette: {
          nieuwColor,
          oudColor,
          externAppColor,
          primary1Color = "",
          accent1Color = "",
        },
      },
      landscape: {
        rectangles = empty.array,
        lines = empty.array,
      } = empty.object,
      filters: {
        filter = empty.array,
        selectedItemIds = empty.object,
      } = empty.object,
    } = this.props;
    const rect = rectangles[r];
    const relatedLinks = flatten(
      Object.keys(lines)
        .filter(
          (l) => lines[l].from === rect.itmIdt || lines[l].to === rect.itmIdt
        )
        .map((l) => lines[l].links)
    );
    const hasHiglightedUsing = some(Boolean)(
      map(usingHighlighted(selectedItemIds))(relatedLinks)
    );

    const status = get("item.status.alias")(rect);
    const validity = get("item.validity.alias")(rect);
    const archived = validity === "archief";
    const color = archived
      ? oudColor
      : { nieuw: nieuwColor, oud: oudColor, extern: externAppColor }[status] ??
        primary1Color;
    return (
      <Rectangle
        key={r}
        r={rect}
        primaryColor={color}
        secondaryColor={accent1Color}
        selectedItemIds={selectedItemIds}
        highlighted={
          selectedItemIds[rect.itmIdt] ||
          filter.length === 0 ||
          hasHiglightedUsing
        }
        archived={archived}
      />
    );
  }

  renderLandscape() {
    const {
      landscape: {
        size: { height = 0, width = 0 } = empty.object,
        rectangles = empty.array,
        lines = empty.array,
      } = empty.object,
    } = this.props;

    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height}>
        <g className="landscape">
          <defs>
            {this.renderMarkers()}
            {this.renderMarkers("none")}
            {this.renderMarkers("auto")}
            {this.renderMarkers("semi")}
            {this.renderMarkers("hand")}
          </defs>
          {lines && Object.keys(lines).map(this.renderLine)}
          {rectangles && Object.keys(rectangles).map(this.renderRectangle)}
        </g>
      </svg>
    );
  }

  render() {
    const {
      theme: {
        palette: { primary1Color = "" },
      },
      landscape: {
        rectangles = empty.array,
        unconnected = empty.array,
      } = empty.object,
      landscapeItem,
      pages = empty.object,
      popup,
      linkIcon,
      containerWidth,
      documentSize: { height = 0 } = empty.object,
    } = this.props;

    return (
      <>
        <Card style={{ padding: "16px", marginBottom: "16px" }}>
          <div className="landscape-wrapper" style={{ display: "block" }}>
            <LandscapeToolbar
              landscapeItem={landscapeItem}
              color={primary1Color}
              tool={this.state.tool}
              fullscreen={this.state.fullscreen}
              toggleFullscreen={this.toggleFullscreen}
              toggleNone={this.toggleNone}
              togglePan={this.togglePan}
              toggleZoomIn={this.toggleZoomIn}
              toggleZoomOut={this.toggleZoomOut}
              fitToViewer={this.fitToViewer}
            />
            <div
              onMouseOver={disableScroll}
              onMouseMove={disableScroll}
              onMouseLeave={enableScroll}
              onMouseOut={enableScroll}
            >
              <ReactSVGPanZoom
                width={containerWidth - 32}
                height={
                  this.state.fullscreen && height - 200 > 500
                    ? height - 200
                    : 500
                }
                ref={(Viewer) => (this.Viewer = Viewer)}
                tool={this.state.tool}
                detectWheel
                detectAutoPan={false}
                background="#fff"
                miniaturePosition="none"
                toolbarPosition="none"
              >
                {this.renderLandscape()}
              </ReactSVGPanZoom>
            </div>
            {linkIcon.show && (
              <ContentLink
                color={primary1Color}
                style={{
                  position: "absolute",
                  top: linkIcon.position.y,
                  left: linkIcon.position.x,
                }}
              />
            )}
          </div>
          <Dialog
            actions={[
              <FlatButton
                label="Sluiten"
                key="sluiten"
                primary
                onClick={this.handleClose}
              />,
            ]}
            open={(popup.id && popup.grouped) !== undefined}
            onRequestClose={this.handleClose}
            autoScrollBodyContent
          >
            <LandscapePopup
              popup={popup}
              rectangles={rectangles}
              pages={pages}
            />
          </Dialog>
        </Card>
        {!this.state.fullscreen && unconnected.length > 0 && (
          <Card style={{ marginTop: "24px" }}>
            <Subheader>Applicaties zonder koppeling</Subheader>
            <ItemLinkList list={sortBy("label")(unconnected)} maxLength={6} />
          </Card>
        )}
      </>
    );
  }
}

export default Landscape;
