/* eslint  react/prop-types: off */
import classNames from "classnames";
import empty from "empty";
import { compact, get, head, map } from "lodash/fp";
import React from "react";

import { flown } from "../../../lodash";
import { ItemLink } from "../../relation.itemlink";
import { createPath } from "./utils";

const truncate = (value, maxLength) =>
  value.length <= maxLength ? value : `${value.substring(0, maxLength - 2)}...`;

export const Rectangle = ({
  r = empty.object,
  primaryColor = "",
  secondaryColor = "",
  highlighted = false,
  archived = false,
}) => (
  <g className={classNames("node", { highlighted, archived })}>
    <ItemLink key={r.id} item={r.item}>
      <rect
        x={r.point.x}
        y={r.point.y}
        width={r.width}
        height={r.height}
        fill={r.self ? secondaryColor : primaryColor}
      />
      <text
        x={r.point.x + r.width / 2}
        y={r.point.y + r.height / 2}
        alignmentBaseline="middle"
        textAnchor="middle"
        fill="white"
      >
        {truncate(r.text, 15)}
      </text>
      <title>{r.text}</title>
    </ItemLink>
  </g>
);

const LinkingLine = ({ l, handlePopupDialog, children }) => (
  <g className="line">
    {l.links && l.links.length > 0 ? (
      /* eslint-disable */
      <a
        xlinkHref="#"
        onClick={(e) => {
          e.preventDefault();
          return handlePopupDialog(l, {
            x: e.nativeEvent.offsetX,
            y: e.nativeEvent.offsetY,
          });
        }}
      >
        {/* eslint-enable */}
        {children}
      </a>
    ) : (
      children
    )}
  </g>
);

export const Line = ({
  l = empty.object,
  showLinkIcon,
  hideLinkIcon,
  highlighted = false,
  ...rest
}) => {
  const automationLevel = l.links
    ? head(compact(l.links.map((l) => l.automationLevel))) || "none"
    : "none";
  const lifecycle = flown(l, get("links"), map("lifecycle"), compact, head);
  const archived =
    l.links?.length > 0 &&
    l.links.every((link) => link.siteLink?.validity?.alias === "archief");
  return (
    <LinkingLine l={l} {...rest}>
      {l.links && l.links.length > 0 && (
        <path
          className="transparent"
          d={createPath(l.path)}
          fill="transparent"
          stroke="transparent"
          strokeWidth="16"
          onMouseOver={showLinkIcon}
          onMouseOut={hideLinkIcon}
        />
      )}
      <path
        className={classNames("real", automationLevel, lifecycle, {
          archived,
          highlighted,
        })}
        d={createPath(l.path)}
        fill="transparent"
        strokeWidth="1"
        markerStart={l.both ? `url(#arrow_rev${automationLevel})` : null}
        markerEnd={
          typeof l.both === "boolean" ? `url(#arrow${automationLevel})` : null
        }
        onMouseOver={l.links && l.links.length > 0 ? showLinkIcon : undefined}
        onMouseOut={l.links && l.links.length > 0 ? hideLinkIcon : undefined}
      />
    </LinkingLine>
  );
};
