import empty from "empty";
import { debounce, defaultTo, get, groupBy } from "lodash/fp";
import Dimensions from "react-dimensions";
import { compose, mapProps, pure, setDisplayName, withState } from "recompose";

import { getSinglePageAction } from "../../../actions/data";
import connect from "../../../containers/connect";
import { flown } from "../../../lodash";
import Landscape from "./landscape";
import { direction } from "./utils";

export default compose(
  setDisplayName("Landscape"),
  pure,
  connect(
    ({
      ui,
      ui: {
        viewport: { documentSize = empty.object } = empty.object,
      } = empty.object,
      data,
    }) => ({
      theme: ui.theme,
      pages: data.pages,
      documentSize,
    }),
    (dispatch) => ({
      requireKoppeling: (id) => dispatch(getSinglePageAction(id)),
    })
  ),
  withState("popup", "update", empty.object),
  withState("linkIcon", "toggleLinkIcon", {
    position: { x: 0, y: 0 },
    show: false,
  }),
  mapProps(
    ({
      popup,
      update,
      requireKoppeling,
      linkIcon,
      toggleLinkIcon,
      ...rest
    }) => {
      const lazyToggle = debounce(300)(toggleLinkIcon);

      return {
        handlePopupDialog: (line) => {
          if (!line) {
            update(empty.object);
          } else {
            if (line && line.links) {
              for (const {
                siteLink: { itemId = 0 } = empty.object,
              } of line.links) {
                requireKoppeling(itemId);
              }
            }

            update({
              id: line.id,
              grouped: flown(
                line,
                get("links"),
                defaultTo(empty.array),
                groupBy(direction)
              ),
            });
          }
        },

        showLinkIcon: (e) => {
          lazyToggle.cancel();
          toggleLinkIcon({
            position: {
              x: e.nativeEvent.offsetX - 24,
              y: e.nativeEvent.offsetY - 24,
            },
            show: true,
          });
        },

        hideLinkIcon: () => {
          lazyToggle.cancel();
          lazyToggle({
            position: { x: 0, y: 0 },
            show: false,
          });
        },

        linkIcon,

        popup,

        ...rest,
      };
    }
  )
)(Dimensions({ elementResize: true })(Landscape));
