import empty from "empty";

export const isLandscape = (
  { size: { width } = empty.object } = empty.object
) => width > 0;

export const createPath = (path = empty.array) =>
  path
    .map((p, i) => (i === 0 ? `M ${p.x} ${p.y}` : `L ${p.x} ${p.y}`))
    .join(" ");

export const usingHighlighted =
  (selectedItemIds) =>
  ({ siteLink: { itemId: koppeling } = empty.object }) =>
    selectedItemIds[koppeling];

export const direction = (link) =>
  `${link.direction.from}:${link.direction.to}`;
