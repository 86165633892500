import { composeAction } from "async-lifecycle";
import empty from "empty";
import {
  compact,
  debounce,
  defaultTo,
  filter,
  get,
  has,
  identity,
  includes,
  last,
  map,
  uniq,
  values,
} from "lodash/fp";
import NotaBene from "material-ui/svg-icons/alert/error";
import React from "react";
import { batch } from "react-redux";
import { createSelector } from "reselect";

import {
  lockPage,
  restorePage,
  savePage,
  unlockPage,
  uploadFile,
} from "../api";
import { Side } from "../business/relations";
import { iproxUrl } from "../config";
import { flown } from "../lodash";
import { base64 } from "../utils";
import { addStage } from "./common";
import {
  getPageAction,
  getSinglePageAction,
  requireClusterDefinition,
} from "./data";

const relationGroups = ["RELATIONS", "VERSIONS", "TREE"];
const contentGroups = ["PAGE", "TREE", "RAAMWERK"];

const bufferedDispatch = createSelector([identity], (realDispatch) => {
  const buffer = [];
  const debounced = debounce(200)(() => {
    if (buffer.length > 0) {
      batch(() => buffer.forEach((action) => realDispatch(action)));
      buffer.length = 0;
    }
  });
  return (action, force) => {
    buffer.push(action);
    debounced();
    if (force) {
      debounced.flush();
    }
    return Promise.resolve();
  };
});

export const invalidatePage = (id) => ({
  type: `PAGE_INVALIDATE`,
  payload: { id },
});

export const RelationTypeGroups = {
  entiteitApplicatieGegeven: "EntiteitApplicatieGegeven",
  entiteitApplicatieVeld: "EntiteitApplicatieVeld",
  zibApplicatieGegeven: "ZibApplicatieGegeven",
  zibApplicatieVeld: "ZibApplicatieVeld",
};

export class PageActions {
  constructor(dispatch, pageId, data = empty.object) {
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        this[key] = data[key];
      }
    }
    this.dispatch =
      dispatch === empty.func ? empty.func : bufferedDispatch(dispatch);
    this.pageId = pageId;
  }

  create = (type, payload = empty.object) => ({
    type,
    payload: { ...payload, id: this.pageId },
  });

  raise = (type, payload = empty.object, dispatch = this.dispatch) =>
    dispatch(this.create(type, payload));

  add = (type, payload = empty.object, restage = false) => {
    if (restage) {
      this.raise("FORM_PAGE_UNSTAGE", {
        $type: `InfoZorgSAAR.Iprox.ContentActions.${type}Action, SAAR.Iprox`,
      });
    }

    this.raise("FORM_PAGE_STAGE", {
      $type: `InfoZorgSAAR.Iprox.ContentActions.${type}Action, SAAR.Iprox`,
      ...payload,
    });
  };

  clear = () => this.raise("FORM_PAGE_REVERT");

  cancelCluster = () => this.raise("FORM_PAGE_UNSTASH");

  stashCluster = () => this.raise("FORM_PAGE_STASH");

  revert = () =>
    this.dispatch(
      addStage({
        message: "De wijzigingen worden teruggedraaid.",
        action: this.create("FORM_PAGE_REVERT"),
      })
    );

  restore = (versionId) =>
    this.dispatch(
      addStage({
        message: "De huidige versie wordt overschreven met deze versie.",
        extra: () => (
          <div className="alert">
            <NotaBene className="icon" />
            <p>
              De inhoud van de pagina wordt overschreven, maar de relaties met
              andere items worden niet aangepast.
            </p>
          </div>
        ),
        action: composeAction({
          group: "API_PAGERESTORE",
          fire: restorePage,
          args: [this.pageId, versionId],
          callbackAfter: (_, dispatch) => {
            dispatch({ type: "PAGE_INVALIDATE", payload: { id: this.pageId } });
            dispatch({
              type: "VERSIONS_INVALIDATE",
              payload: { id: this.pageId },
            });

            // sluit versie-balk
            dispatch({
              type: "UI_SHOWVERSIONSDRAWER",
              payload: { show: false },
            });
            // sluit versie-preview-dialoog
            dispatch({ type: "UI_VERSIONDIFF_CLEAR" });

            dispatch(getPageAction(this.pageId));
          },
        }),
      })
    );

  save = (forceOpen = false, callbackAfter = empty.func) => {
    const keepOpen = forceOpen === true;
    this.raise("FORM_PAGE_SET", { busy: true, edit: false });
    this.dispatch((dispatch, getState) => {
      const {
        form: {
          page: {
            [this.pageId]: { staged },
          },
          page: pagesStore,
        },
        data: {
          relations: {
            [this.pageId]: { relations = empty.array } = empty.object,
          },
          relations: relationsStore,
        },
      } = getState();

      dispatch(
        composeAction({
          group: "SAVE_PAGE",
          fire: () => savePage(this.pageId, staged),
          callbackAfter: (job, dispatch) => {
            const invalidate = (groups) => (id) => {
              const payload = { id };
              for (const group of groups) {
                dispatch({
                  type: `${group}_INVALIDATE`,
                  payload,
                });
              }
            };
            const invalidateContent = invalidate(contentGroups);
            if (has(this.pageId)(pagesStore)) {
              invalidateContent(this.pageId);
            }

            // Invalidate other updated items
            const otherIds = flown(staged, map("itemId"), compact, uniq);
            for (const id of otherIds) {
              if (!has(id)(pagesStore)) {
                continue;
              }

              invalidateContent(id);
            }

            // Invalidate relations of self and related pages
            const relatedItemIds = [this.pageId];
            for (const { side, left, right, using } of relations) {
              if (side !== Side.left) {
                relatedItemIds.push(left.itemId);
              }

              if (side !== Side.right) {
                relatedItemIds.push(right.itemId);
              }

              if (side !== Side.using) {
                for (const { itemId } of using) {
                  relatedItemIds.push(itemId);
                }
              }
            }

            const hasKoppelingFieldsUpdateAction = includes(
              "InfoZorgSAAR.Iprox.ContentActions.KoppelingFieldsUpdateAction, SAAR.Iprox"
            )(staged.map(({ $type }) => $type));
            if (hasKoppelingFieldsUpdateAction) {
              // relations of this page needs to invalidate
              relatedItemIds.push(this.pageId);
            }

            const processItemIds = flown(
              staged,
              filter({
                $type:
                  "InfoZorgSAAR.Iprox.ContentActions.KoppelingFieldsUpdateAction, SAAR.Iprox",
              }),
              map("processItemId")
            );
            for (const id of processItemIds) {
              relatedItemIds.push(id);
            }

            if (processItemIds.length > 0) {
              relatedItemIds.push(this.pageId);
            }

            const invalidateRelations = invalidate(relationGroups);
            for (const id of uniq(relatedItemIds)) {
              if (!has(id)(relationsStore)) {
                continue;
              }

              invalidateRelations(id);
            }

            dispatch(
              composeAction(getPageAction(this.pageId), {
                callbackSuccess: () =>
                  this.raise("FORM_PAGE_CLEAR", { edit: Boolean(keepOpen) }),
                callbackError: () =>
                  this.raise("FORM_PAGE_SET", { busy: false, edit: true }),
              })
            );
            for (const id of otherIds) {
              dispatch(getSinglePageAction(id));
            }

            if (callbackAfter) {
              callbackAfter(job);
            }
          },
          callbackError: () =>
            this.raise("FORM_PAGE_SET", { busy: false, edit: true }),
        })
      );
    }, true);
  };

  toggleEdit = () => {
    if (!this.edit) {
      // als je gaat bewerken, sluit VersionsDrawer
      this.raise("UI_SHOWVERSIONSDRAWER", { show: false });
      this.raise("FORM_PAGE_SET", { busy: true });
      this.dispatch(
        composeAction({
          group: "LOCK_TRY",
          fire: () => lockPage(this.pageId),
          callbackBefore: (dispatch) =>
            dispatch(getSinglePageAction(this.pageId, true)),
          callbackSuccess: (lock, dispatch) => {
            const success = Boolean(lock && lock.success);
            this.raise("FORM_PAGE_SET", {
              lock,
              edit: success,
              busy: false,
            });
            if (success) {
              return;
            }
            dispatch(
              addStage({
                message: `${lock.user} is om ${lock.time} begonnen deze pagina te bewerken. Als je verder gaat kunnen gegevens verloren gaan.`,
                action: composeAction({
                  group: "LOCK_FORCE",
                  fire: () => lockPage(this.pageId, true),
                  callbackBefore: (dispatch) =>
                    this.raise("FORM_PAGE_SET", { busy: true }, dispatch),
                  callbackSuccess: (lock, dispatch) =>
                    this.raise(
                      "FORM_PAGE_SET",
                      {
                        lock,
                        edit: true,
                        busy: false,
                      },
                      dispatch
                    ),
                }),
              })
            );
          },
          callbackError: () => {
            this.raise("FORM_PAGE_SET", { busy: false });
          },
        })
      );
    } else {
      this.dispatch(getSinglePageAction(this.pageId, false, true));
      this.unlock();
    }
  };

  unlock = () => {
    this.raise("FORM_PAGE_SET", { lock: empty.object, edit: false });
    this.dispatch(
      composeAction({
        group: "UNLOCK",
        fire: () => unlockPage(this.pageId),
      })
    );
  };

  titleRevert = ({ itemId } = empty.object) => {
    this.raise("FORM_PAGE_UNSTAGE", {
      itemId,
      $type: "InfoZorgSAAR.Iprox.ContentActions.TitleUpdateAction, SAAR.Iprox",
    });
  };

  titleUpdate = ({ value, itemId }) => {
    this.titleRevert({ itemId });
    this.add("TitleUpdate", { value, itemId });
  };

  fieldRevertPayload = ({ itemId, fieldId, clusterId, name }) =>
    fieldId > 0 ? { itemId, fieldId } : { itemId, clusterId, name };

  fieldUpdate = ({ itemId, fieldId, value, values, data, revert }) =>
    this.add("FieldUpdate", {
      itemId,
      fieldId,
      value,
      values,
      data,
      revert: this.fieldRevertPayload(revert),
    });

  fieldRevert = (revert) => {
    this.raise("FORM_PAGE_UNSTAGE", this.fieldRevertPayload(revert));
  };

  fieldAdd = ({ itemId, clusterId, name, value, values, data, revert }) =>
    this.add("FieldAdd", {
      itemId,
      clusterId,
      name,
      value,
      values,
      data,
      revert: this.fieldRevertPayload(revert),
    });

  fieldCopy = ({ itemId, sourceItemId, sourceFieldId }) =>
    this.add("FieldCopy", {
      itemId,
      sourceItemId,
      sourceFieldId,
    });

  fieldCopyRevert = ({ itemId, sourceItemId, sourceFieldId }) =>
    this.raise("FORM_PAGE_UNSTAGE", {
      $type: "InfoZorgSAAR.Iprox.ContentActions.FieldCopyAction, SAAR.Iprox",
      itemId,
      sourceItemId,
      sourceFieldId,
    });

  requireClusters = ({ clusterId, name }) =>
    this.dispatch(requireClusterDefinition(this.pageId, clusterId, name), true);

  clusterAdd = ({ clusterId, name }) =>
    this.requireClusters({ clusterId, name }).then(() =>
      this.clusterAddRequiredClusterAlreadyPresent({ clusterId, name })
    );

  clusterAddRequiredClusterAlreadyPresent = ({ clusterId, name }) =>
    this.add("ClusterAdd", { clusterId, name });

  clusterRemove = ({ parentId, clusterId, name }) =>
    this.add("ClusterRemove", { parentId, clusterId, name });

  clusterRevert = ({ parentId, clusterId, name }) =>
    this.raise("FORM_PAGE_UNSTAGE", {
      $type:
        "InfoZorgSAAR.Iprox.ContentActions.ClusterRemoveAction, SAAR.Iprox",
      parentId,
      clusterId,
      name,
    });

  clusterSetRelations = (
    { clusterId, relations, relationTypeGroup },
    clean = false
  ) => {
    if (!flown(RelationTypeGroups, values, includes(relationTypeGroup))) {
      throw new Error(
        "relationTypeGroup must be a value of RelationTypeGroups"
      );
    }

    this.raise("FORM_PAGE_UNSTAGE", {
      $type:
        "InfoZorgSAAR.Iprox.ContentActions.ClusterReplaceRelationsAction, SAAR.Iprox",
      clusterId,
      relationTypeGroup,
    });
    if (!clean) {
      this.add("ClusterReplaceRelations", {
        clusterId,
        relations,
        relationTypeGroup,
      });
    }
  };

  clusterOrder = ({ clusterId, name, clusterIds }) =>
    this.add("ClusterOrder", { clusterId, name, clusterIds });

  dashboardShift = ({ blokken }) =>
    this.add("ShiftDashboardBlokken", { pageId: this.pageId, blokken });

  dashboardShiftRevert = () => {
    this.raise("FORM_PAGE_UNSTAGE", {
      $type:
        "InfoZorgSAAR.Iprox.ContentActions.ShiftDashboardBlokkenAction, SAAR.Iprox",
      pageId: this.pageId,
    });
  };

  dashboardDeleteBlok = ({ clusterId }) =>
    this.add("DeleteDashboardBlok", { clusterId });

  selectRelation = (relation, selected) =>
    this.raise(`FORM_PAGE_${selected ? "SELECT" : "UNSELECT"}`, { relation });
}

export const uploadImageAction =
  (pageId, blobInfo, success, failure) => (dispatch) =>
    base64(blobInfo.blob()).then((data) =>
      dispatch(
        composeAction({
          group: "IMAGE_UPLOAD",
          fire: (filename, data) => uploadFile(pageId, filename, data),
          args: [blobInfo.filename(), data],
          callbackAfter: ({ location } = empty.object) =>
            success(`${iproxUrl}${location}`),
          callbackError: ({ message }) => failure(message),
        })
      )
    );

export const pageEditAction = (itemId) => ({
  type: "FORM_PAGE_SET",
  payload: { id: itemId, edit: true },
});

export const pageActions = createSelector(
  [
    identity,
    (_, pageId) => pageId,
    (_, _1, { lock = empty.object }) => lock,
    (_, _1, { busy = false }) => busy,
    (_, _1, { dirty = false }) => dirty,
    (_, _1, { edit = false }) => edit,
    (_, _1, { edited = false }) => edited,
    (_, _1, { version = 0 }) => version,
    (_, _1, { staged = empty.array }) =>
      flown(
        staged,
        filter(
          ({ $type }) =>
            typeof $type === "string" && /ClusterRemoveAction/.test($type)
        ),
        map("clusterId")
      ),
    (_, _1, { staged = empty.array }) =>
      flown(
        staged,
        filter(
          ({ $type }) =>
            typeof $type === "string" && /ClusterAddAction/.test($type)
        ),
        last,
        get("relations"),
        defaultTo(empty.array)
      ),
    (_, _1, { staged = empty.array }) => staged,
  ],
  (
    dispatch,
    pageId,
    lock,
    busy,
    dirty,
    edit,
    edited,
    version,
    removed,
    selectedRelations,
    staged
  ) =>
    new PageActions(dispatch, pageId, {
      busy,
      dirty,
      lock,
      edit,
      edited,
      version,
      removed,
      selectedRelations,
      staged,
    })
);
