import classNames from "classnames";
import { compact, concat, flatten, map, reject, some } from "lodash/fp";
import { MenuItem, SelectField } from "material-ui";
import Checkbox from "material-ui/Checkbox";
import { RadioButton, RadioButtonGroup } from "material-ui/RadioButton";
import ContentCreate from "material-ui/svg-icons/content/create";
import Container from "material-ui/svg-icons/file/folder";
import React, {
  MouseEvent,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { flown } from "../../lodash";
import { uuidv4 } from "../../utils";
import InlineIconButton from "../material/InlineIconButton";
import { ItemLink } from "../relation.itemlink";

const styles = {
  icon: { width: 16, height: 16, opacity: 0.5, marginRight: 4 },
};

const ZibLabel = memo(
  ({ container, label }: { container: boolean; label: string }) => (
    <div className="clusterpicker-label">
      {container && <Container style={styles.icon} />}
      <span>{label}</span>
    </div>
  )
);

interface TypedLink {
  itemId: number;
  pageClusterId?: number;
  type: string;
}

interface Node extends TypedLink {
  label: string;
}

interface ParentNode extends Node {
  items: Node[];
}

interface Props {
  items: ParentNode[];
  clusterRequired?: boolean;
  multiple?: boolean;
  selected: TypedLink[];
  onChange: (selected: TypedLink[]) => void;
  labelText: string;
  disabled?: boolean;
}

const geenItem: ParentNode = {
  itemId: -1,
  label: "Geen",
  type: "-",
  items: [],
};

const clusters = (item: Node): Node[] => {
  const children = (item as ParentNode).items;
  return children
    ? flown(children, map(clusters), flatten, concat(children))
    : [];
};

const Check = ({
  item,
  handleChange,
  selected,
  level,
}: {
  item: Node;
  handleChange: (selected: TypedLink[]) => void;
  selected: TypedLink[];
  level: number;
}): JSX.Element => {
  const currentChecked = some(
    (s: TypedLink): boolean =>
      s.itemId === item.itemId && s.pageClusterId === item.pageClusterId
  )(selected);

  const handleCheck = useCallback(
    (e: unknown, checked: boolean): void => {
      if (currentChecked === checked) {
        return;
      }

      const next = reject(
        (s: TypedLink): boolean =>
          s.itemId === item.itemId && s.pageClusterId === item.pageClusterId
      )(selected);
      if (checked) {
        next.push(item);
      }

      handleChange(next);
    },
    [currentChecked, handleChange, item, selected]
  );

  return (
    <Checkbox
      key={JSON.stringify(item)}
      value={JSON.stringify(item)}
      label={
        <ZibLabel
          container={!item.type || item.type === "applicatiegegeven_zib"}
          label={item.label}
        />
      }
      onCheck={handleCheck}
      disabled={!item.type}
      checked={currentChecked}
      className={classNames(
        "clusterpicker-check",
        `clusterpicker-${item.type ?? "container"}`,
        { "clusterpicker-checked": currentChecked }
      )}
      labelStyle={{ marginLeft: 24 * level, marginRight: -24 * level }}
    />
  );
};

const checks = (
  parents: ParentNode[],
  handleChange: (selected: TypedLink[]) => void,
  selected: TypedLink[],
  level = 0
): JSX.Element[] => {
  return parents.flatMap(({ items: children, ...parentProps }) => {
    const parent = { ...parentProps } as Node;
    return [
      <Check
        key={JSON.stringify(parent)}
        item={parent}
        handleChange={handleChange}
        selected={selected}
        level={level}
      />,
      ...flown(
        children,
        map((item: Node) =>
          checks([item as ParentNode], handleChange, selected, level + 1)
        ),
        flatten
      ),
    ];
  });
};

const radios = (
  parent: Node,
  handleClick: (e: any) => void,
  selected: TypedLink[],
  level = 0
): JSX.Element[] =>
  flown(
    (parent as ParentNode).items,
    map((item: Node) => {
      const checked = some(
        (s: TypedLink): boolean => s.pageClusterId === item.pageClusterId
      )(selected);
      return [
        <RadioButton
          key={item.pageClusterId}
          value={JSON.stringify(item)}
          label={<ZibLabel container={!item.type} label={item.label} />}
          onClick={handleClick}
          disabled={!item.type ?? "container"}
          className={classNames(
            "clusterpicker-radio",
            `clusterpicker-${item.type}`,
            {
              "clusterpicker-checked": checked,
            }
          )}
          labelStyle={{ marginLeft: 24 * level, marginRight: -24 * level }}
        />,
        ...radios(item, handleClick, selected, level + 1),
      ];
    })
  );

const ClusterPicker = ({
  items,
  selected: selectedArg,
  clusterRequired = false,
  multiple = false,
  onChange,
  labelText,
  disabled = false,
}: Props): JSX.Element => {
  const emptyText = `Niet gebaseerd op ${labelText.toLowerCase()}`;
  const name = useMemo(uuidv4, []);
  const selected = useMemo(
    (): Node[] =>
      flown(
        selectedArg,
        map((link: TypedLink) => {
          const item = items.find(
            ({ itemId }): boolean => itemId === link.itemId
          );

          return !item || !link.pageClusterId
            ? item
            : clusters(item).find(
                ({ pageClusterId }) => pageClusterId === link.pageClusterId
              );
        }),
        compact
      ),
    [items, selectedArg]
  );

  const [selectedItems, setSelectedItems] = useState<Node[]>(
    multiple
      ? items.filter(({ itemId }) =>
          selected?.map((s) => s.itemId).includes(itemId)
        )
      : [items.find(({ itemId }) => itemId === selected[0]?.itemId) ?? geenItem]
  );
  useEffect(() => {
    if (selected.length === 0 || multiple) {
      return;
    }

    /* only for single selections (radio's) */
    setSelectedItems([
      items.find(({ itemId }) => itemId === selected[0].itemId) ?? geenItem,
    ]);
  }, [items, multiple, selected]);

  const [expanded, setExpanded] = useState(false);
  const expand = useCallback(() => setExpanded(true), []);
  useEffect(() => {
    if (disabled) {
      setExpanded(false);
    }
  }, [disabled]);

  const handleSelectSingleItem = useCallback(
    (_event: unknown, _index: unknown, itemId: number) => {
      const value = items.find((item) => item.itemId === itemId) ?? geenItem;
      if (clusterRequired || value === geenItem) {
        onChange([]);
        window.setTimeout((): void => {
          setSelectedItems([value]);
        }, 25);
      } else {
        onChange([value]);
      }
    },
    [clusterRequired, items, onChange]
  );

  const handleSelectMultipleItems = useCallback(
    (_event: unknown, _index: unknown, itemIds: number[]) => {
      const value = items.filter((item) => itemIds.includes(item.itemId));
      const removedItems = selected
        ?.map((s) => s.itemId)
        .filter((itemId) => !itemIds.includes(itemId));

      window.setTimeout((): void => {
        setSelectedItems(value);
      }, 25);

      if (value.length === 0) {
        onChange([]);
      } else if (removedItems.length > 0) {
        const filtered = selected.filter((item) =>
          itemIds.includes(item.itemId)
        );
        onChange(filtered);
      }
    },
    [items, onChange, selected]
  );

  const handleChangeChild = useCallback(
    (e: unknown, value: string) => {
      if (!expanded) {
        return;
      }

      onChange([JSON.parse(value)]);
    },
    [expanded, onChange]
  );

  const handleClickRadio = useCallback(
    (e: MouseEvent) => {
      if (
        !clusterRequired &&
        JSON.parse((e.target as HTMLInputElement).value).pageClusterId ===
          selected[0]?.pageClusterId
      ) {
        onChange(selectedItems);
      }
    },
    [clusterRequired, selected, onChange, selectedItems]
  );

  const currentDisplayValue = useMemo(
    (): JSX.Element[] =>
      selected.length > 0 ? (
        flown(
          selected,
          map((link: TypedLink) => {
            const item = items.find(
              ({ itemId }): boolean => itemId === link.itemId
            );
            if (!item) {
              return undefined;
            }

            if (!link.pageClusterId) {
              return (
                <div key={`itm-${item.itemId}`}>
                  <ItemLink item={item} />
                </div>
              );
            }

            const cluster = clusters(item).find(
              ({ pageClusterId }) => pageClusterId === link.pageClusterId
            );
            if (!cluster) {
              return undefined;
            }

            return (
              <div key={`pagcls-${cluster.pageClusterId}`}>
                <ItemLink
                  item={{
                    ...cluster,
                    pageClusterLabel: cluster.label,
                    label: item.label,
                  }}
                  clusterFirst
                />
              </div>
            );
          }),
          compact
        )
      ) : (
        <div>{emptyText}</div>
      ),
    [emptyText, items, selected]
  );

  if (!expanded) {
    return (
      <>
        {selected.length === 0 ? (
          <em>{emptyText}</em>
        ) : (
          <div style={{ display: "inline-block" }}>{currentDisplayValue}</div>
        )}{" "}
        {!disabled && (
          <InlineIconButton onClick={expand}>
            <ContentCreate />
          </InlineIconButton>
        )}
      </>
    );
  }

  const currentSelection = multiple
    ? selectedItems.map((i) => i.itemId)
    : selectedItems[0].itemId;

  return (
    <div className="value" onClick={expand}>
      <SelectField
        floatingLabelText={labelText}
        fullWidth
        value={currentSelection}
        onChange={multiple ? handleSelectMultipleItems : handleSelectSingleItem}
        style={{ marginTop: -16 }}
        multiple={multiple}
      >
        {flown(
          [multiple ? null : geenItem, ...items],
          compact,
          map((item: Node) => (
            <MenuItem
              key={item.itemId}
              value={item.itemId}
              primaryText={item.label}
            />
          ))
        )}
      </SelectField>
      <br />
      {multiple ? (
        checks(selectedItems as ParentNode[], onChange, selected)
      ) : (
        <RadioButtonGroup
          name={name}
          valueSelected={selected[0] ? JSON.stringify(selected[0]) : undefined}
          onChange={handleChangeChild as () => void}
          labelPosition="left"
        >
          {radios(selectedItems[0], handleClickRadio, selected)}
        </RadioButtonGroup>
      )}
    </div>
  );
};

export default ClusterPicker;
