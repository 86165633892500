import { asyncReducer, clearReducer } from "async-lifecycle";
import empty from "empty";
import { isEqual, without } from "lodash/fp";
import { combineReducers } from "redux";

import { reducer as addItem } from "../saar-add-item/redux";
import { drop } from "../utils";
import page from "./page";

const mapItems = ({ data }) => ({
  items: data.map(
    ({
      itmIdt: itemId,
      sitIdt: siteId,
      lbl: label,
      bieb,
      container,
      disabled,
    }) => ({
      itemId,
      siteId,
      label,
      bieb,
      container,
      disabled,
    })
  ),
});

const addRelation = clearReducer(
  "ADDRELATION",
  combineReducers({
    add: asyncReducer("ADDRELATION_ADD"),
    context: asyncReducer("ADDRELATION_CONTEXT"),
    form: asyncReducer("ADDRELATION_INITIALFORM"),
    filter: asyncReducer("PERSONROLE_FILTER", {
      INIT: (state, { extra: { source } = empty.object }) => ({
        loading: "init",
        source,
        itemIds: source ? empty.array : undefined,
      }),
    }),
    peers: asyncReducer("ADDRELATION_PEERS", { selector: mapItems }),
    useFilter: (
      state = true,
      { type, payload: { useFilter = true } = empty.object }
    ) => (type === "PERSONROLE_TOGGLE" ? useFilter : state),
    using: asyncReducer("ADDRELATION_USING", { selector: mapItems }),
  })
);

const addCluster = asyncReducer("ADDCLUSTER", {
  CLUSTERDEFINITION_PICK: (state, { payload }) =>
    isEqual(state)(payload) ? state : payload,
});

const linkRaamwerk = clearReducer(
  "LINKRAAMWERK",
  combineReducers({
    add: asyncReducer("LINKRAAMWERK_ADD"),
    items: asyncReducer("LINKRAAMWERK_ITEMS"),
  })
);

const mail = asyncReducer("SEND_MAIL", {
  selector: empty.functionThatReturnsArgument,
});

const note = asyncReducer("UPDATE_ITEM_NOTE", {
  selector: empty.functionThatReturnsArgument,
});

const globalQuery = (
  state = empty.object,
  { type, payload = empty.object }
) => {
  switch (type) {
    case "MINISAAR_INIT":
    case "GLOBALQUERY_CLEAR":
      return empty.object;
    case "GLOBALQUERY_SET":
      return isEqual(state)(payload) ? state : payload;
    default:
      return state;
  }
};

const searchQuery = (
  state = empty.object,
  { type, payload: { uid, property, value } = empty.object }
) => {
  switch (type) {
    case "QUERY_CLEAR":
      return drop(state, uid);
    case "QUERY_SET": {
      const previous = state[uid] || empty.object;
      if (previous[property] === value) {
        return state;
      }
      return { ...state, [uid]: { ...previous, [property]: value } };
    }
    default:
      return state;
  }
};

const legoblokDialog = (
  state = empty.object,
  { type, payload: { open = false } = empty.object }
) => {
  switch (type) {
    case "CMS_LEGOBLOK_CREATE_INIT":
      return { ...state, busy: true };
    case "CMS_LEGOBLOK_CREATE_SUCCESS":
      return { ...state, busy: false };
    case "CMS_LEGOBLOK_CREATE":
      return { ...state, open };
    default:
      return state;
  }
};

const legoblokUseDialog = (
  state = empty.object,
  { type, payload: { open = false } = empty.object }
) => {
  switch (type) {
    case "CMS_LEGOBLOK_USE_INIT":
      return { ...state, busy: true };
    case "CMS_LEGOBLOK_USE_SUCCESS":
      return { ...state, busy: false };
    case "CMS_LEGOBLOK_USEINIT":
      return { ...state, open };
    default:
      return state;
  }
};

const applicationGegevenDialog = (
  state = empty.object,
  { type, payload: { open = false } = empty.object }
) => {
  switch (type) {
    case "APPLICATION_GEGEVEN_ADD_DIALOG":
      return { ...state, open };
    case "APPLICATION_GEGEVEN_SAVE_INIT":
      return { ...state, busy: true };
    case "APPLICATION_GEGEVEN_SAVE_SUCCESS":
      return { ...state, busy: false };
    default:
      return state;
  }
};

const biebAddDialog = (
  state = empty.object,
  { type, payload: { open = false } = empty.object }
) => {
  switch (type) {
    case "CMS_BIEB_ADD_INIT":
      return { ...state, busy: true };
    case "CMS_BIEB_ADD_SUCCESS":
      return { ...state, busy: false };
    case "CMS_BIEB_ADD":
      return { ...state, open };
    default:
      return state;
  }
};

const emptyBiebSelect = Object.freeze({ selected: empty.array });
const biebSelect = (
  state = emptyBiebSelect,
  { type, payload: { selected = empty.array } = empty.object }
) => {
  switch (type) {
    case "CMS_BIEB_SELECT_CLEAR":
      return emptyBiebSelect;
    case "CMS_BIEB_SELECT_SET":
      return selected.length === 0 ? emptyBiebSelect : { selected };
    default:
      return state;
  }
};

const biebUseDialog = (
  state = empty.object,
  { type, payload: { open = false, multi, created } = empty.object }
) => {
  switch (type) {
    case "CMS_BIEB_USE_INIT":
      return { ...state, busy: true };
    case "CMS_BIEB_USE_SUCCESS":
      return { ...state, busy: false };
    case "CMS_BIEB_USE_ERROR":
      return { ...state, error: true, busy: false };
    case "CMS_BIEB_USE_SET":
      return { ...state, error: false, open, multi, created };
    default:
      return state;
  }
};

const biebUpdateDialog = (
  state = empty.object,
  {
    type,
    payload: {
      open = false,
      biebItemId,
      localItemId,
      hasUpdate,
    } = empty.object,
  }
) => {
  switch (type) {
    case "CMS_BIEB_UPDATE_INIT":
      return { ...state, busy: true };
    case "CMS_BIEB_UPDATE_SUCCESS":
      return { ...state, busy: false };
    case "CMS_BIEB_UPDATE_ERROR":
      return { ...state, error: true, busy: false };
    case "CMS_BIEB_UPDATE_SET":
      return {
        ...state,
        error: false,
        open,
        biebItemId,
        localItemId,
        hasUpdate,
      };
    default:
      return state;
  }
};

const biebPreviewDialog = (
  state = empty.object,
  { type, payload: { itemId } = empty.object }
) => {
  switch (type) {
    case "CMS_BIEB_PREVIEW_SET":
      return { ...state, open: typeof itemId === "number", itemId };
    case "CMS_BIEB_PREVIEW_CLEAR":
      return empty.object;
    default:
      return state;
  }
};

export const initialKoppelingGegevenDialogState = Object.freeze({
  open: false,
});

const koppelingGegevenDialog = (
  state = initialKoppelingGegevenDialogState,
  { type, payload = empty.object }
) => {
  switch (type) {
    case "KOPPELING_GEGEVEN_DIALOG":
      return { ...state, ...payload };
    default:
      return state;
  }
};

const initialOverzicht = Object.freeze({
  title: "",
  pagetype: "",
  fields: empty.array,
  relations: empty.array,
  currentStep: 0,
});

const overzicht = (state, { type, payload = empty.object }) => {
  switch (type) {
    case "FORM_OVERZICHT_CLEAR":
      return initialOverzicht;
    case "FORM_OVERZICHT_SET":
      return payload;
    default:
      return state || initialOverzicht;
  }
};

const restoreVersion = asyncReducer("API_PAGERESTORE");

const oneDriveFile = asyncReducer("ONEDRIVE_INFO", {
  hasId: true,
  FORM_PAGE_STAGE: (state, { type }) =>
    type === "FORM_PAGE_STAGE" ? empty.object : state,
});

const raamwerkOpen = (
  state = empty.object,
  { type, payload: { id, itemId, value } = empty.object }
) => {
  switch (type) {
    case "RAAMWERKOPEN_CLEARALL":
      return empty.object;
    case "RAAMWERKOPEN_CLEAR":
      return { ...state, [id]: empty.object };
    case "RAAMWERKOPEN_TOGGLE": {
      const map = state[id] || empty.object;
      return { ...state, [id]: { ...map, [itemId]: !map[itemId] } };
    }
    case "RAAMWERKOPEN_SET": {
      const map = state[id] || empty.object;
      return { ...state, [id]: { ...map, [itemId]: !!value } };
    }
    default:
      return state;
  }
};

const wordBasket = (
  state = empty.array,
  { type, payload: { id } = empty.object, payload: items = empty.array }
) => {
  switch (type) {
    case "WORDBASKET_ADD":
      return [...state, id];
    case "WORDBASKET_SET":
      return items;
    case "WORDBASKET_CLEAR":
      return empty.array;
    case "WORDBASKET_DELETE":
      return without([id])(state);
    default:
      return state;
  }
};

export default clearReducer(
  "FORM",
  combineReducers({
    addCluster,
    addItem,
    addRelation,
    applicationGegevenDialog,
    biebAddDialog,
    biebPreviewDialog,
    biebSelect,
    biebUseDialog,
    biebUpdateDialog,
    exportOverzicht: asyncReducer("EXPORT"),
    globalQuery,
    koppelingGegevenDialog,
    legoblokDialog,
    legoblokUseDialog,
    linkRaamwerk,
    mail,
    note,
    oneDriveFile,
    overzicht,
    page,
    raamwerkOpen,
    restoreVersion,
    searchQuery,
    wordBasket,
  }),
  (state, { payload: { type } }) => ({ ...state, [type]: empty.object })
);
