/* eslint  react/prop-types: off */
import FlatButton from "material-ui/FlatButton";
import IconButton from "material-ui/IconButton";
import { grey600 } from "material-ui/styles/colors";
import ActionPanTool from "material-ui/svg-icons/action/pan-tool";
import ActionTouchApp from "material-ui/svg-icons/action/touch-app";
import ActionZoomIn from "material-ui/svg-icons/action/zoom-in";
import ActionZoomOut from "material-ui/svg-icons/action/zoom-out";
import MapsZoomOutMap from "material-ui/svg-icons/maps/zoom-out-map";
import NavigationFullscreen from "material-ui/svg-icons/navigation/fullscreen";
import React from "react";

import { ItemLink } from "../../relation.itemlink";

const LandscapeToolbar = ({
  color,
  fitToViewer,
  fullscreen,
  landscapeItem,
  toggleFullscreen,
  toggleNone,
  togglePan,
  toggleZoomIn,
  toggleZoomOut,
  tool,
}) => (
  <div
    className="landscape-toolbar-wrapper"
    style={{
      display: "flex",
      borderBottom: `1px solid ${grey600}`,
      marginBottom: "16px",
    }}
  >
    {landscapeItem && (
      <div>
        <FlatButton
          style={{ marginTop: "8px" }}
          label={`Toon ${landscapeItem.label}`}
          containerElement={<ItemLink item={landscapeItem} />}
        />
      </div>
    )}
    <div
      className="landscape-toolbar"
      style={{
        textAlign: "right",
        flexGrow: "3",
      }}
    >
      <IconButton title="Venster opvullen" onClick={fitToViewer}>
        <MapsZoomOutMap color={grey600} />
      </IconButton>
      <IconButton title="Selecteren" onClick={toggleNone}>
        <ActionTouchApp color={tool === "none" ? color : grey600} />
      </IconButton>
      <IconButton title="Schuiven" onClick={togglePan}>
        <ActionPanTool color={tool === "pan" ? color : grey600} />
      </IconButton>
      <IconButton title="Zoom in" onClick={toggleZoomIn}>
        <ActionZoomIn color={tool === "zoom-in" ? color : grey600} />
      </IconButton>
      <IconButton title="Zoom uit" onClick={toggleZoomOut}>
        <ActionZoomOut color={tool === "zoom-out" ? color : grey600} />
      </IconButton>
      <IconButton title="Volledig scherm" onClick={toggleFullscreen}>
        <NavigationFullscreen color={fullscreen ? color : grey600} />
      </IconButton>
    </div>
  </div>
);

export default LandscapeToolbar;
